<template>
  <div v-if="survey">
    <h1>{{ survey.title }}</h1>
    <h4 class="text-info">Average: {{ Math.round(responsesAvg) }}</h4>
    <v-client-table class="bg-light p-2 vue-table-table w-100" name="dataTableSurveyResults" :columns="columns"
      :data="surveyResponses" :options="options" :useVuex="true" id="surveyResultsTable" ref="survey-results-table">
      <div slot="id" slot-scope="props">
        <b-button size="sm" variant="info" @click="responseId = props.row.id">View</b-button>
      </div>
      <div slot="user" slot-scope="props">
        {{ props.row.user.fullName }}
      </div>
      <div slot="submittedOn" slot-scope="props">
        {{ $moment(props.row.submittedOn).format('MM/DD/YY h:mma') }}
      </div>
      <div slot="surveyQuestionResponses" slot-scope="props">
        {{ averageResponse(props.row.surveyQuestionResponses) }}
      </div>
    </v-client-table>

    <!-- MODAL FOR VIEWING SURVEY RESPONSE -->
    <b-modal v-model="showResponse" @hide="responseId = null" size="lg" hide-footer>
      <template #modal-title>
        <span>Survey Response</span>
      </template>
      <div v-if="responseId">
        <Survey :surveyData="survey" :responseData="surveyResponses.find(r => r.id == responseId)" :readOnly="true" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import { ClientTable } from 'vue-tables-2-premium'
import { mapGetters, mapActions } from 'vuex'
import Survey from '@/components/Survey.vue'
Vue.use(ClientTable)

export default {
  name: 'SurveyResults',
  components: {
    ClientTable,
    Survey
  },
  props: {
    surveyId: {
      type: [Number, String],
      required: true
    },
    userId: {
      type: String,
      required: false,
      default: null
    },
    year: {
      type: [Number, String],
      required: false,
      default: null
    },
    month: {
      type: [Number, String],
      required: false,
      default: null
    },
    day: {
      type: [Number, String],
      required: false,
      default: null
    },
  },
  data() {
    return {
      // TABLE OPTIONS
      options: {
        debounce: 750,
        perPage: 30,
        perPageValues: [30, 60, 90],
        headings: {
          id: '',
          submittedOn: 'Submitted',
          user: 'User',
          surveyQuestionResponses: 'Response'
        },
        sortable: ['submittedOn'/* , 'user', 'surveyQuestionResponses' */],
        filterable: ['submittedOn', 'user', 'surveyQuestionResponses'],
        sortIcon: { base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      responseId: null,
      showResponse: false,
      survey: null,
      surveyResponses: [],
    }
  },
  computed: {
    url() {
      return 'survey_responses'
    },
    columns() {
      let cols = ['id', 'submittedOn', 'user', 'surveyQuestionResponses']
      return cols
    },
    timeLine() {
      return this.day ? 'day' : 'month'
    },
    dateToStart() {
      return this.$moment(this.year + '-' + this.month + '-' + (this.day || 1)).startOf(this.timeLine)
    },
    dateToEnd() {
      return this.$moment(this.year + '-' + this.month + '-' + (this.day || this.dateToStart.daysInMonth())).endOf(this.timeLine)
    },
    responsesAvg() {
      return this.surveyResponses.map(r => this.averageResponse(r.surveyQuestionResponses)).reduce((a, b) => a + b, 0) / this.surveyResponses.length
    },
  },
  mounted() {
    this.doGetSurvey()
    this.doGetSurveyResponses()
  },
  watch: {
    responseId() {
      this.showResponse = !!this.responseId
    },
    year() {
      this.doGetSurveyResponses()
    },
    month() {
      this.doGetSurveyResponses()
    },
  },
  methods: {
    ...mapActions(['getSurvey', 'getSurveyResponses']),
    async doGetSurvey() {
      let resp = await this.getSurvey(this.surveyId)
      this.survey = resp
    },
    async doGetSurveyResponses() {
      let resp = await this.getSurveyResponses({
        pagination: false,
        survey: this.surveyId,
        user: this.userId,
        startDate: this.dateToStart.format('YYYY-MM-DD'),
        endDate: this.dateToEnd.format('YYYY-MM-DD')
      })
      this.surveyResponses = resp
    },
    averageResponse(responses) {
      const resp = Object.values(responses);
      if (resp.length === 0) {
        return 0;
      }
      const sum = resp.reduce((acc, resp) => acc + resp.response, 0);
      return Math.round(sum / resp.length);
    },
  }
}
</script>